import { Router } from '@angular/router';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import * as _ from 'lodash';
import { InvertersService } from '../inverters/inverters.service';

declare const BMapGL;
declare const BMAP_ANCHOR_BOTTOM_RIGHT;
declare const BMAP_EARTH_MAP;
declare const BMAP_NORMAL_MAP;

@Component({
  selector: 'app-webgl-ln-wind',
  templateUrl: './webgl-ln-wind.component.html',
  styleUrls: ['./webgl-ln-wind.component.less'],
})
export class WebglLnWindComponent implements OnInit, AfterViewInit, OnChanges {
  imgSrc: string;

  wind: any = {};
  @Input() stationId: String;
  @Output() deviceDetailEvent = new EventEmitter();
  interval: any;

  constructor(
    private router: Router,
    private invertersservice: InvertersService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.getStationInverters();
      this.interval = setInterval(() => {
        this.getStationInverters();
      }, 1000 * 60 * 1);
    }
  }

  getCenterCoordinates(coords) {
    let sumLongitude = 0;
    let sumLatitude = 0;

    coords.forEach((coord) => {
      sumLongitude += +coord.longitude;
      sumLatitude += +coord.latitude;
    });

    const total = coords.length;

    if (total) {
      return {
        longitude: sumLongitude / total,
        latitude: sumLatitude / total,
      };
    } else {
      return {};
    }
  }

  public async getStationInverters() {
    const data = await this.invertersservice.getStationInverters({
      stationId: this.stationId,
    });
    const stations = data.devices
      ? data.devices.map((item) => {
          return {
            ...item,
            longitude: item.longitude,
            latitude: item.latitude,
            deviceId: item.deviceId,
            status: ['2', '3'].includes(item.statusGroup) ? '1' : '0',
          };
        })
      : [];

    /************************** 定义地图 **************************/
    let map = new BMapGL.Map('container');
    const center = this.getCenterCoordinates(stations);
    let point = new BMapGL.Point(116.404, 39.915);
    if (!_.isEmpty(center)) {
      point = new BMapGL.Point(center.longitude, center.latitude);
    }

    console.log(point);

    map.centerAndZoom(point, 14);

    map.setHeading(0);
    map.setTilt(60);
    map.enableScrollWheelZoom();
    map.setDisplayOptions({
      skyColors: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)'],
    });

    let navigationCtl3D = new BMapGL.NavigationControl3D();
    navigationCtl3D.setAnchor(BMAP_ANCHOR_BOTTOM_RIGHT);
    navigationCtl3D.setOffset({ width: 20, height: 20 });
    map.addControl(navigationCtl3D); // 3D控件
    console.dir(navigationCtl3D.getAnchor());
    console.dir(navigationCtl3D.getOffset());

    map.addControl(new BMapGL.ScaleControl()); // 添加比例尺控件
    // map.addControl(new BMapGL.ZoomControl()); // 添加缩放控件
    map.addControl(new BMapGL.MapTypeControl());

    /************************** 添加行政区区域 **************************/
    function addOverlary(regionName) {
      bd.get(regionName, function (rs) {
        let count = rs.boundaries.length; //行政区域的点有多少个
        let pointArray = [];
        for (let i = 0; i < count; i++) {
          let path = [];
          let str = rs.boundaries[i].replace(' ', '');
          let points = str.split(';');
          for (let j = 0; j < points.length; j++) {
            let lng = points[j].split(',')[0];
            let lat = points[j].split(',')[1];
            path.push(new BMapGL.Point(lng, lat));
          }
          let prism = new BMapGL.Prism(path, 5000, {
            topFillColor: '#076273',
            topFillOpacity: 0.1,
            sideFillColor: '#076273',
            sideFillOpacity: 0.2,
            enableMassClear: false,
          });
          map.addOverlay(prism);
        }
      });
    }

    let bd = new BMapGL.Boundary();
    //  addOverlary('辽宁');
    //  addOverlary('沈阳');
    //  addOverlary('抚顺');
    //  addOverlary('辽阳');
    //  addOverlary('营口');
    //  addOverlary('大连');
    //  addOverlary('盘锦');
    //  addOverlary('阜新');
    //  addOverlary('锦州');
    //  addOverlary('朝阳');
    //  addOverlary('葫芦岛');
    //  addOverlary('丹东');
    //  addOverlary('鞍山');
    //  addOverlary('本溪');
    //  addOverlary('铁岭');

    /************************** 添加电站图标 **************************/

    // 定义图标样式
    let icons = {
      '1': 'assets/map/images/wind.gif',
      '0': 'assets/map/images/wind.png',
    };

    /************************** 地图样式 **************************/
    map.setMapStyleV2({ styleJson: window['styleJson'] });
    map.setMapType(BMAP_EARTH_MAP);
    // map.setMapType(BMAP_NORMAL_MAP);

    function triggerMapType(e) {
      let btn = document.getElementById('map-type-btn');
      if (map.getMapType() == BMAP_NORMAL_MAP) {
        map.setMapType(BMAP_EARTH_MAP);
        btn.innerHTML = '普通地图';
      } else {
        map.setMapType(BMAP_NORMAL_MAP);
        btn.innerHTML = '卫星地图';
      }
    }

    map.addEventListener('click', function (e) {
      console.log('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
    });

    if (stations.length === 0) {
      return;
    }

    const points = stations.map((item) => {
      return new BMapGL.Point(item.longitude, item.latitude);
    });
    let polyline = new BMapGL.Polyline(points, {
      strokeColor: 'rgb(9, 116, 178)',
      strokeWeight: 2,
      strokeOpacity: 0.5,
    });
    map.addOverlay(polyline);

    // 创建电站图标
    for (let i = 0; i < stations.length; ++i) {
      // 创建Marker标注，使用小车图标
      // let marker = new BMapGL.Marker(
      //   new BMapGL.Point(stations[i].longitude, stations[i].latitude),
      //   {
      //     icon: icons[stations[i].status],
      //   }
      // );

      let marker = new BMapGL.Label(
        `<img src="${
          icons[stations[i].status]
        }" style="width:59px;height:84px" />`,
        {
          offset: {
            width: -30,
            height: -84,
          },
          position: new BMapGL.Point(
            stations[i].longitude,
            stations[i].latitude
          ),
        }
      );
      marker.setStyle({
        backgroundColor: 'unset',
        border: 'none',
      });
      marker.station = stations[i];

      marker.addEventListener('mouseover', (e) => {
        const ele = document.getElementById('info_window');
        ele.style.display = 'block';
        ele.style.left = e.pixel.x + 16 + 'px';
        ele.style.top = e.pixel.y + 'px';
        ele.className = 'energyType01';
        this.imgSrc = `assets/map/images/energyType${marker.station.energyType}.png`;
        const index = _.findIndex(
          stations,
          (item: any) => item.deviceId === marker.station.deviceId
        );
        this.wind = stations[index];
      });
      marker.addEventListener('mouseout', function (e) {
        const ele = document.getElementById('info_window');
        ele.style.display = 'none';
        ele.className = null;
      });

      marker.addEventListener('click', (e) => {
        this.deviceDetailEvent.emit({
          deviceId: marker.station.deviceId,
          stationId: this.stationId,
          deviceType: 'FJ',
        });
      });

      map.addOverlay(marker);
    }
  }

  ngAfterViewInit(): void {}
}
