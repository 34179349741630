import { TipService } from '@common/services/tip.service';
import { CacheService } from './../../../common/services/cache.service';
import { BreadcrumbService } from 'src/app/components/layout/breakcrumb/breadcrumb.service';
import { JsonPipe, Location } from '@angular/common';
import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { EarthMapBgService } from './earth-map-bg.service';
import { Constants } from '@common/Constants';
import { StationMonitorService } from '@components/station-monitor/station-monitor.service';
import CesiumPopup from './CesiumPopup';
import { AppConfigService } from 'src/app/app.config';

declare const XE: any;
declare const Cesium: any;

@Component({
  selector: 'app-earth-map-bg',
  templateUrl: './earth-map-bg.component.html',
  styleUrls: ['./earth-map-bg.component.less'],
})
export class EarthMapBgComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() earthStepPage;
  @Input() scene;

  @Output() stationIdEvent = new EventEmitter();
  @Output() pageChangeEvent = new EventEmitter();
  @Output() showInvertersEvent = new EventEmitter();
  @Output() countyDataEvent = new EventEmitter();

  // 显示电站信息
  showTip = false;
  // 电站信息面板位置
  winPos = [];
  // cesium
  earth: any = null;
  viewer: any = null;
  camera: any = null;
  previousHoverId = null;
  // 光伏电站连线
  _gfodlines: any = null;
  // 山地风机连线
  _sdodlines: any = null;
  // 海面风机连线
  _hmodlines: any = null;
  // cesium 事件handler
  handler: any = null;
  // 节流timer
  timer = null;
  // 旋转风机模型
  rollModel = [];
  // 风机旋转定时器
  rollInterval = [];
  // 风机旋转速度
  rollSpeed = 5;
  // 风机旋转角度
  roll = 0;
  // 摄像机飞行状态 防止点击停止
  cameraFlying = false;
  // 光伏电站列表
  photovoltaicList = [];
  // 风电列表
  windList = [
    {
      id: 'SD#01',
      name: '风机 SD#01',
      longitude: 122.39117604774088,
      latitude: 29.84420483524789,
      height: 363.4715942172321,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#02',
      name: '风机 SD#02',
      longitude: 122.3883260270445,
      latitude: 29.84648286801263,
      height: 358.46195631964923,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#03',
      name: '风机 SD#03',
      longitude: 122.38604175020646,
      latitude: 29.847838538134738,
      height: 333.78511714181803,
      type: 'SD',
      status: 'error',
    },
    {
      id: 'SD#04',
      name: '风机 SD#04',
      longitude: 122.38346202995392,
      latitude: 29.848743372097246,
      height: 279.0917553300854,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#05',
      name: '风机 SD#05',
      longitude: 122.37915353387748,
      latitude: 29.849246399081398,
      height: 237.61962964025471,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#06',
      name: '风机 SD#06',
      longitude: 122.37909862157821,
      latitude: 29.845818295610858,
      height: 99.21256678396945,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#07',
      name: '风机 SD#07',
      longitude: 122.3830626024132,
      latitude: 29.844220413820462,
      height: 120.84056382127515,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#08',
      name: '风机 SD#08',
      longitude: 122.38467986544276,
      latitude: 29.84328991084332,
      height: 122.06645198501839,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#09',
      name: '风机 SD#09',
      longitude: 122.38755059159121,
      latitude: 29.84074237737903,
      height: 95.50719893249328,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'SD#10',
      name: '风机 SD#10',
      longitude: 122.39040764786762,
      latitude: 29.838635295956617,
      height: 75.12574002036622,
      type: 'SD',
      status: 'online',
    },
    {
      id: 'HM#01',
      name: '风机 HM#01',
      longitude: 122.41960174164993,
      latitude: 29.83534494726535,
      height: 0,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#02',
      name: '风机 HM#02',
      longitude: 122.41727249106336,
      latitude: 29.829437404847084,
      height: 0,
      type: 'HM',
      status: 'offline',
    },
    {
      id: 'HM#03',
      name: '风机 HM#03',
      longitude: 122.41604104950379,
      latitude: 29.825034239989495,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#04',
      name: '风机 HM#04',
      longitude: 122.41519706475033,
      latitude: 29.82277135163345,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#05',
      name: '风机 HM#05',
      longitude: 122.4131604560155,
      latitude: 29.81885130928604,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#06',
      name: '风机 HM#06',
      longitude: 122.41115788342701,
      latitude: 29.814377760226275,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#07',
      name: '风机 HM#07',
      longitude: 122.41008879704266,
      latitude: 29.81090552731409,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#08',
      name: '风机 HM#08',
      longitude: 122.40791651428913,
      latitude: 29.807218041907742,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#09',
      name: '风机 HM#09',
      longitude: 122.40717823616419,
      latitude: 29.804364505812817,
      type: 'HM',
      status: 'online',
    },
    {
      id: 'HM#10',
      name: '风机 HM#10',
      longitude: 122.40456343825205,
      latitude: 29.797689511630082,
      type: 'HM',
      status: 'online',
    },
    {
      id: '#01',
      name: '变电站#01',
      longitude: 122.4100596351664,
      latitude: 29.83221119383473,
      height: 34.48227676999591,
      type: 'station',
      status: 'online',
    },
  ];
  // 区域页电站信息
  // 电站总部
  stationHq: any = null;
  // 电站列表
  stations = [];

  stationTypeMap = {};

  // 移入电站 显示数据
  stationData = {};

  stationId = '';
  isCounty: boolean = false;
  stationAreaMap: any = {};
  showAreaTip: boolean;
  stationAreaData: any = {};
  isShow: boolean;
  showConfig: boolean;
  isStationShow: boolean;
  runStatus: string;
  sceneMode: string = '3D';
  centerLongitude: any;
  centerLatitude: any;
  mapType: any = '卫星';
  subStations: any[];

  statusMode = 'areaStatus';
  level: string;
  stationMap: any = [];
  showStationTip: boolean = false;
  stationInfoData: any = [];
  modelinstancecollection: any = {};
  station3dLocation: { lat: any; lng: any };
  scenesTree: any;
  NbPopup: CesiumPopup;

  constructor(
    private earthmapbgservice: EarthMapBgService,
    private location: Location,
    private activatedroute: ActivatedRoute,
    private breadcrumbservice: BreadcrumbService,
    private cacheservice: CacheService,
    private router: Router,
    private tipservice: TipService,
    private stationmonitorservice: StationMonitorService,
    private appconfig: AppConfigService
  ) {
    // this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     // this.path = event.url.split("?")[0];
    //     // console.log(event.url);
    //     console.log(event);
    //   });

    this.activatedroute.queryParams.subscribe((res) => {
      if (res.level === 'station') {
        this.level = 'station';
        if (res.stationId) {
          this.stationId = res.stationId;
          this.breadcrumbservice.setStationSource({
            stationId: this.stationId,
          });
        } else {
          this.stationId = this.cacheservice.getCache(
            Constants.KEY_CURRENT_STATION_ID
          );
          this.breadcrumbservice.setStationSource({
            stationId: this.stationId,
          });
        }
      } else {
        if (res.stationId) {
          this.stationId = res.stationId;
        }
        if (
          ['/yxjs', '/scgl', '/yyfx', '/xtpz'].includes(this.location.path())
        ) {
          this.stationId = '';
          this.breadcrumbservice.setStationSource('');
        }
      }
    });

    this.breadcrumbservice.stationSourceHandler.subscribe((res: any) => {
      if (res.stationId) {
        const station = <any>(
          this.stations.filter((item) => item.stationId === res.stationId)
        );
        if (station[0].showType === 'county') {
          this.pageChangeEvent.emit('county_photovoltaic_page');
          this.showInvertersEvent.emit({ show: false });
        } else if (station[0].showType === '3dDevice') {
          this.pageChangeEvent.emit('photovoltaic_page');
          this.showInvertersEvent.emit({ show: false });
        } else if (station[0].showType === 'inverters') {
          this.pageChangeEvent.emit('monitor');
          this.showInvertersEvent.emit({ show: true, type: 'inverters' });
        } else if (station[0].showType === 'overview') {
          this.pageChangeEvent.emit('monitor');
          this.showInvertersEvent.emit({
            show: true,
            type: 'overview',
            stationClass: station[0].stationClass,
          });
        }

        this.stationIdEvent.emit(res.stationId);
      } else {
        this.pageChangeEvent.emit('monitor');
        this.stationIdEvent.emit('');
      }
    });

    this.earthmapbgservice.stationAreaHandler.subscribe((runStatus) => {
      if (runStatus === 0) {
        this.runStatus = '0';
      } else {
        this.runStatus = null;
      }
      this.isStationShow = true;
    });
  }

  ngOnInit() {}

  async ngAfterViewInit() {
    const { hq: hqData, stations: stationsData } =
      await this.earthmapbgservice.getStations();

    this.stationHq = hqData;
    this.stations = stationsData;

    XE.ready().then(() => {
      Cesium.Ion.defaultAccessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyOWRkMGQ4Yy1mODA5LTQ3YTYtODM0YS1kNjI2MGQ1ZDYzYzEiLCJpZCI6NDU3ODYsImlhdCI6MTY2NjMxMzU4N30.ybYL_9wkVshtWWtk2VxGzEhP9ASrgoYGlgRRB72X8ME';
      this.earth = new XE.Earth('earthContainer', {
        shadows: false,
        shouldAnimate: false,
        animation: true,
        homeButton: false,
        geocoder: false,
        baseLayerPicker: false,
        timeline: false,
        fullscreenButton: false,
        scene3DOnly: false,
        infoBox: false,
        sceneModePicker: false,
        navigationInstructionsInitiallyVisible: false,
        navigationHelpButton: false,
        selectionIndicator: false,
      });
      window['earth'] = this.earth;
      this.viewer = this.earth.czm.viewer;
      window['viewer'] = this.viewer;
      this.viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      this.viewer.animation.container.style.display = 'none'; //隐藏动画控件
      this.viewer.cesiumWidget.creditContainer.style.display = 'none';
      this.viewer.scene.globe.enableLighting = true;
      this.camera = this.viewer.scene.camera;

      this.earth.sceneTree.root = {
        children: [
          {
            czmObject: {
              name: '谷歌影像',
              xbsjType: 'Imagery',
              xbsjImageryProvider: {
                XbsjImageryProvider: {
                  url: 'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                  maximumLevel: 18,
                },
              },
            },
          },
          {
            czmObject: {
              name: '高德地图',
              xbsjType: 'Imagery',
              xbsjImageryProvider: {
                XbsjImageryProvider: {
                  url: '//webrd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}',
                  maximumLevel: 18,
                  rectangle: [-Math.PI, -Math.PI * 0.5, Math.PI, Math.PI * 0.5],
                  srcCoordType: 'GCJ02', //实时纠偏
                  dstCoordType: 'WGS84',
                },
              },
            },
            enabled: false,
          },
          // {
          //   czmObject: {
          //     xbsjType: "Terrain",
          //     name: "cesium官方",
          //     xbsjTerrainProvider: {
          //       type: "XbsjCesiumTerrainProvider",
          //       XbsjCesiumTerrainProvider: {
          //         url: "Ion(1)", // Cesium官方资源都可以通过 Ion(<资源ID号>) 这种形式访问
          //         requestVertexNormals: true,
          //         requestWaterMask: true,
          //       },
          //     },
          //   },
          // },
          // {
          //   "czmObject": {
          //     "xbsjType": "Imagery",
          //     "name": 'gao d',
          //     "xbsjImageryProvider": {
          //       "XbsjImageryProvider": {
          //         "url": "https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          //         srcCoordType: 'GCJ02',  //实时纠偏
          //         dstCoordType: 'WGS84',
          //         minimumLevel: 3,
          //         maximumLevel: 22
          //       }
          //     }
          //   },
          // }
        ],
      };
      this.earth.sceneTree.root.children[1].enabled = false;
      this.initPage();
      this.initMouseHandler(this.viewer);
    });
  }

  public initPage() {
    if (!_.isEmpty(this._gfodlines)) {
      this._gfodlines.destroy();
    }
    // 清除光伏电站
    this.photovoltaicList.map((item) => {
      let getById = this.viewer.entities.getById(item.id);
      this.viewer.entities.remove(getById);
    });
    // 删除电站连线
    if (!_.isEmpty(this._hmodlines)) {
      this._hmodlines.destroy();
    }
    // 删除电站连线
    if (!_.isEmpty(this._sdodlines)) {
      this._sdodlines.destroy();
    }
    // 清除风电电站
    this.windList.map((item) => {
      let getById = this.viewer.entities.getById(item.id);
      let getByIdFan = this.viewer.entities.getById(item.id + '_fan');
      let getByIdColumn = this.viewer.entities.getById(item.id + '_column');
      this.viewer.entities.remove(getById);
      this.viewer.entities.remove(getByIdFan);
      this.viewer.entities.remove(getByIdColumn);
    });

    if (this.modelinstancecollection) {
      const object = this.modelinstancecollection;
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const element = object[key];
          this.viewer.scene.primitives.remove(element);
        }
      }
    }

    // 停止旋转
    this.stopRoll();

    this.viewer.clockViewModel.multiplier = 0;
    this.viewer.scene.globe.enableLighting = false;

    this.earth.sceneTree.root.children.push({
      ref: this.stationHq.stationId || 'hq',
      czmObject: {
        name: this.stationHq.stationId || 'hq',
        xbsjType: 'Pin',
        position: [
          Cesium.Math.toRadians(+this.stationHq.log),
          Cesium.Math.toRadians(+this.stationHq.lat),
          this.stationHq.alt ? +this.stationHq.alt : 0,
        ],
        near: 100,
        imageUrl: '/resources/earth/img/center.png',
      },
    });

    const longitudes = [];
    const latitudes = [];

    this.stations
      .filter((item) => {
        return item.log && item.lat;
      })
      .map((item) => {
        longitudes.push(+item.log);
        latitudes.push(+item.lat);

        this.earth.sceneTree.root.children.push({
          ref: item.stationId,
          czmObject: {
            name: item.stationId,
            xbsjType: 'Pin',
            position: [
              Cesium.Math.toRadians(+item.log),
              Cesium.Math.toRadians(+item.lat),
              item.alt ? +item.alt : 0,
            ],
            near: 100,
            imageUrl:
              item.stationClass === '02'
                ? '/resources/earth/img/fengdian.png'
                : '/resources/earth/img/guangfu.png',
          },
        });
        this.stationTypeMap[item.stationId] = item;
      });

    this.centerLongitude = null;
    this.centerLatitude = null;
    if (longitudes.length > 0) {
      // this.centerLongitude =
      //   longitudes.reduce((a, b) => +a + +b) / longitudes.length;

      let max = _.max(longitudes);
      let min = _.min(longitudes);

      this.centerLongitude = (max + min) / 2;
    }
    if (latitudes.length > 0) {
      // this.centerLatitude =
      //   latitudes.reduce((a, b) => +a + +b) / latitudes.length;
      let max = _.max(latitudes);
      let min = _.min(latitudes);

      this.centerLatitude = (max + min) / 2;
    }
    this.cameraFlying = true;
    this.viewer.camera.flyToBoundingSphere(
      new Cesium.BoundingSphere(
        Cesium.Cartesian3.fromDegrees(
          this.centerLongitude,
          this.centerLatitude,
          0
        ),
        555000
      ),
      {
        complete: () => {
          this.cameraFlying = false;
        },
      }
    );
    this.loadCesiumLines();
    if (this.level === 'station') {
      const station = <any>(
        this.stations.filter((item) => item.stationId === this.stationId)
      );
      if (station[0].showType === 'county') {
        this.pageChangeEvent.emit('county_photovoltaic_page');
      } else if (station[0].showType === '3dDevice') {
        this.pageChangeEvent.emit('photovoltaic_page');
      } else if (station[0].showType === '3dStation') {
        this.station3dLocation = { lat: station[0].lat, lng: station[0].log };
        this.pageChangeEvent.emit('3d_station_page');
      } else if (station[0].showType === 'inverters') {
        this.pageChangeEvent.emit('inverters_page');
        this.showInvertersEvent.emit({ show: true, type: 'inverters' });
      } else if (station[0].showType === 'overview') {
        this.pageChangeEvent.emit('inverters_page');
        this.showInvertersEvent.emit({
          show: true,
          type: 'overview',
          stationClass: station[0].stationClass,
        });
      }
      this.stationIdEvent.emit(this.stationId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.earthStepPage &&
      changes.earthStepPage.currentValue
    ) {
      if (changes.earthStepPage.currentValue === 'photovoltaic_page') {
        localStorage.setItem('page', 'station');
        this.enterPhotovoltaicPage();
      } else if (changes.earthStepPage.currentValue === 'wind_power_page') {
        localStorage.setItem('page', 'station');
        this.enterWindPowerPage();
      } else if (
        changes.earthStepPage.currentValue === 'county_photovoltaic_page'
      ) {
        localStorage.setItem('page', 'station');
        this.enterCountyPhotovoltaicPage();
      } else if (changes.earthStepPage.currentValue === 'inverters_page') {
        localStorage.setItem('page', 'station');
        this.enterMonitor();
      } else if (changes.earthStepPage.currentValue === '3d_station_page') {
        localStorage.setItem('page', 'station');
        this.enter3dStation();
      } else {
        localStorage.setItem('page', 'monitor');
        this.enterMonitor();
      }
    }
  }

  // 加载cesium连接线
  public loadCesiumLines() {
    const that = this;
    /*
          流纹纹理线
          color 颜色
          duration 持续时间 毫秒
       */
    function PolylineTrailLinkMaterialProperty(color, duration) {
      this._definitionChanged = new Cesium.Event();
      this._color = undefined;
      this._colorSubscription = undefined;
      this.color = color;
      this.duration = duration;
      this._time = new Date().getTime();
    }
    Cesium.defineProperties(PolylineTrailLinkMaterialProperty.prototype, {
      isConstant: {
        get: function () {
          return false;
        },
      },
      definitionChanged: {
        get: function () {
          return this._definitionChanged;
        },
      },
      color: Cesium.createPropertyDescriptor('color'),
    });
    PolylineTrailLinkMaterialProperty.prototype.getType = function () {
      return 'PolylineTrailLink';
    };
    PolylineTrailLinkMaterialProperty.prototype.getValue = function (
      time,
      result
    ) {
      if (!Cesium.defined(result)) {
        result = {};
      }
      result.color = Cesium.Property.getValueOrClonedDefault(
        this._color,
        time,
        Cesium.Color.WHITE,
        result.color
      );
      result.image = Cesium.Material.PolylineTrailLinkImage;
      result.time =
        ((new Date().getTime() - this._time) % this.duration) / this.duration;
      return result;
    };
    PolylineTrailLinkMaterialProperty.prototype.equals = function (other) {
      return (
        this === other ||
        (other instanceof PolylineTrailLinkMaterialProperty &&
          Cesium.Property.equals(this._color, (other as any)._color))
      );
    };
    Cesium.PolylineTrailLinkMaterialProperty =
      PolylineTrailLinkMaterialProperty;
    Cesium.Material.PolylineTrailLinkType = 'PolylineTrailLink';
    Cesium.Material.PolylineTrailLinkImage = '/resources/earth/img/colors1.png';
    Cesium.Material.PolylineTrailLinkSource =
      'czm_material czm_getMaterial(czm_materialInput materialInput)\n\
                                                      {\n\
                                                           czm_material material = czm_getDefaultMaterial(materialInput);\n\
                                                           vec2 st = materialInput.st;\n\
                                                           vec4 colorImage = texture2D(image, vec2(fract(st.s - time), st.t));\n\
                                                           material.alpha = colorImage.a * color.a;\n\
                                                           material.diffuse = (colorImage.rgb+color.rgb)/2.0;\n\
                                                           return material;\n\
                                                       }';
    Cesium.Material._materialCache.addMaterial(
      Cesium.Material.PolylineTrailLinkType,
      {
        fabric: {
          type: Cesium.Material.PolylineTrailLinkType,
          uniforms: {
            color: new Cesium.Color(1.0, 0.0, 0.0, 0.5),
            image: Cesium.Material.PolylineTrailLinkImage,
            time: 0,
          },
          source: Cesium.Material.PolylineTrailLinkSource,
        },
        translucent: function () {
          return true;
        },
      }
    );

    function parabolaEquation(options, resultOut?) {
      //方程 y=-(4h/L^2)*x^2+h h:顶点高度 L：横纵间距较大者
      var h = options.height && options.height > 5000 ? options.height : 5000;
      var L =
        Math.abs(options.pt1.lon - options.pt2.lon) >
        Math.abs(options.pt1.lat - options.pt2.lat)
          ? Math.abs(options.pt1.lon - options.pt2.lon)
          : Math.abs(options.pt1.lat - options.pt2.lat);
      var num = options.num && options.num > 50 ? options.num : 50;
      var result = [];
      var dlt = L / num;
      if (
        Math.abs(options.pt1.lon - options.pt2.lon) >
        Math.abs(options.pt1.lat - options.pt2.lat)
      ) {
        //以lon为基准
        var delLat = (options.pt2.lat - options.pt1.lat) / num;
        if (options.pt1.lon - options.pt2.lon > 0) {
          dlt = -dlt;
        }
        for (var i = 0; i < num; i++) {
          var tempH =
            h -
            (Math.pow(-0.5 * L + Math.abs(dlt) * i, 2) * 4 * h) /
              Math.pow(L, 2);
          var lon = options.pt1.lon + dlt * i;
          var lat = options.pt1.lat + delLat * i;
          result.push([lon, lat, tempH]);
        }
      } else {
        //以lat为基准
        var delLon = (options.pt2.lon - options.pt1.lon) / num;
        if (options.pt1.lat - options.pt2.lat > 0) {
          dlt = -dlt;
        }
        for (var i = 0; i < num; i++) {
          var tempH =
            h -
            (Math.pow(-0.5 * L + Math.abs(dlt) * i, 2) * 4 * h) /
              Math.pow(L, 2);
          var lon = options.pt1.lon + delLon * i;
          var lat = options.pt1.lat + dlt * i;
          result.push([lon, lat, tempH]);
        }
      }
      if (resultOut != undefined) {
        resultOut = result;
      }
      return result;
    }
    var material = null;

    var center = { lon: +that.stationHq.log, lat: +that.stationHq.lat };
    var cities = that.stations.map((item) => {
      return {
        lon: +item.log,
        lat: +item.lat,
      };
    });

    if (material != null) {
    } else {
      material = new Cesium.PolylineTrailLinkMaterialProperty(
        Cesium.Color.ORANGE,
        1000
      );
    }
    for (var j = 0; j < cities.length; j++) {
      var points = parabolaEquation({
        pt1: cities[j],
        pt2: center,
        height: 100000,
        num: 100,
      });
      var pointArr = [];
      for (var i = 0; i < points.length; i++) {
        pointArr.push(points[i][0], points[i][1], points[i][2]);
      }
      that.viewer.entities.add({
        name: 'PolylineTrailLink' + j,
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(pointArr),
          width: 2,
          material: material,
        },
      });
    }
  }

  public removeCesiumLines() {
    if (!this.viewer) {
      return;
    }
    const allEntities = this.viewer.entities.values;
    const removeItem = [];
    allEntities.map((item) => {
      if (
        item.name.indexOf('hq') > -1 ||
        this.stationTypeMap[item.name] ||
        this.stationAreaMap[item.name] ||
        this.stationMap[item.name] ||
        item.name.indexOf('PolylineTrailLink') > -1
      ) {
        let entities = this.viewer.entities.getById(item.id);
        removeItem.push(entities);
      }
    });
    removeItem.map((item) => {
      this.viewer.entities.remove(item);
    });

    if (this.modelinstancecollection) {
      const object = this.modelinstancecollection;
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const element = object[key];
          this.viewer.scene.primitives.remove(element);
        }
      }
    }
  }

  // 点击事件注册
  // 初始化默认鼠标事件
  public initMouseHandler(viewer) {
    // 鼠标事件
    this.handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

    // 单击事件
    this.handler.setInputAction((movement) => {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        const pick = viewer.scene.pick(movement.position);

        if (pick instanceof Cesium.Cesium3DTileFeature) {
          return;
        }

        let pRadians = viewer.scene.globe.pick(
          viewer.camera.getPickRay(movement.position),
          viewer.scene
        );
        // console.log(pRadians);
        let ellipsoid = viewer.scene.globe.ellipsoid;
        // console.log(ellipsoid);
        let cartographic = ellipsoid.cartesianToCartographic(pRadians);
        let lat = Cesium.Math.toDegrees(cartographic.latitude);
        let lon = Cesium.Math.toDegrees(cartographic.longitude);
        let alt = cartographic.height;
        console.log(
          '世界坐标系: ' +
            pRadians.x +
            ', ' +
            pRadians.y +
            ', ' +
            pRadians.z +
            '\n' +
            '经纬度和海拔' +
            lon +
            ', ' +
            lat +
            ', ' +
            alt
        );
        if (Cesium.defined(pick) && pick.id && pick.id.id) {
          const data = {
            id: pick.id.id,
            name: pick.id.name,
          };
          if (this.earthStepPage === 'monitor') {
            this.cacheservice.setCache(
              Constants.KEY_CURRENT_STATION_NAME,
              this.stationTypeMap[data.name]['stationName']
            );
            if (this.stationTypeMap[data.name]) {
              console.log(
                '🚀 ~ file: earth-map-bg.component.ts:907 ~ EarthMapBgComponent ~ this.timer=setTimeout ~ this.stationTypeMap[data.name]:',
                this.stationTypeMap[data.name]
              );

              this.stationIdEvent.emit(data.name);
              if (this.stationTypeMap[data.name].showType === '3dDevice') {
                if (this.stationTypeMap[data.name].stationClass === '01') {
                  this.pageChangeEvent.emit('photovoltaic_page');
                } else if (
                  this.stationTypeMap[data.name].stationClass === '02'
                ) {
                  this.pageChangeEvent.emit('wind_power_page');
                }
                this.router.navigate([this.router.url.split('?')[0]], {
                  queryParams: {
                    level: 'station',
                  },
                });
              } else if (
                this.stationTypeMap[data.name].showType === '3dStation'
              ) {
                const stationInfo = this.stationTypeMap[data.name];
                this.station3dLocation = {
                  lat: stationInfo.lat,
                  lng: stationInfo.log,
                };
                this.pageChangeEvent.emit('3d_station_page');
                this.router.navigate([this.router.url.split('?')[0]], {
                  queryParams: {
                    level: 'station',
                  },
                });
              } else if (this.stationTypeMap[data.name].showType === 'county') {
                this.pageChangeEvent.emit('county_photovoltaic_page');
                this.router.navigate([this.router.url.split('?')[0]], {
                  queryParams: {
                    level: 'station',
                  },
                });
              } else if (
                this.stationTypeMap[data.name].showType === 'inverters'
              ) {
                this.router.navigate([this.router.url.split('?')[0]], {
                  queryParams: {
                    level: 'station',
                  },
                });
                this.showInvertersEvent.emit({ show: true, type: 'inverters' });
              } else if (
                this.stationTypeMap[data.name].showType === 'overview'
              ) {
                this.router.navigate([this.router.url.split('?')[0]], {
                  queryParams: {
                    level: 'station',
                  },
                });
                this.showInvertersEvent.emit({
                  show: true,
                  type: 'overview',
                  stationClass: this.stationTypeMap[data.name].stationClass,
                });
              } else {
                this.tipservice.tip('warn', '电站未配置展示类型');
              }
            }
          }
        }

        if (
          this.earthStepPage === 'photovoltaic_page' &&
          Cesium.defined(pick) &&
          pick._instanceId
        ) {
          const data = this.stationMap[pick._instanceId];
          if (data.type !== 'ZC') {
            this.deviceDetail(data.id, data.type);
          }
        }
      }, 200);
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    // // 双击选中事件
    // this.handler.setInputAction((movement) => {
    //   if (this.timer) {
    //     clearTimeout(this.timer);
    //     this.timer = null;
    //   }
    //   const pick = viewer.scene.pick(movement.position);
    //   if (pick instanceof Cesium.Cesium3DTileFeature) {
    //     const data = this.getParentNode(this.scenesTree, pick.getProperty('id'));
    //     console.log('postMessage', {
    //       data,
    //       event: 'LEFT_DOUBLE_CLICK',
    //       msgType: 'model3dClick',
    //     });
    //     window.postMessage(
    //       JSON.stringify({
    //         data,
    //         event: 'LEFT_DOUBLE_CLICK',
    //         msgType: 'model3dClick',
    //       }),
    //       '*'
    //     );
    //   }
    // }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

    // 右击事件
    this.handler.setInputAction(() => {
      if (
        this.earthStepPage === 'photovoltaic_page' ||
        this.earthStepPage === '3d_station_page' ||
        this.earthStepPage === 'wind_power_page' ||
        this.earthStepPage === 'county_photovoltaic_page'
      ) {
        this.pageChangeEvent.emit('monitor');
        this.stationIdEvent.emit('');
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    // 移入事件
    this.handler.setInputAction(() => {},
    Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    this.handler.setInputAction(async (movement) => {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        let pick = viewer.scene.pick(movement.endPosition);

        // let point_pos = this.getPointCartesian3(movement.endPosition);
        // console.log(point_pos);

        // const point = new Cesium.Cartesian2(point_pos.x, point_pos.y);

        var position = viewer.scene.pickPosition(movement.endPosition);

        if (pick instanceof Cesium.Cesium3DTileFeature) {
          const data = this.getParentNode(
            this.scenesTree,
            pick.getProperty('id')
          );

          if (
            data &&
            (!this.NbPopup ||
              (this.NbPopup && data && this.NbPopup.title !== data.deviceNo))
          ) {
            data.deviceNo = data.name.split('D_NB_')[1].replaceAll('_', '-');

            if (this.stationId === '10215') {
              data.deviceNo = data.deviceNo.replaceAll('--', '#-');
            }

            let res = await this.earthmapbgservice.getInverter({
              stationId: this.stationId,
              deviceNo: data.deviceNo,
            });

            if (!res) {
              return;
            }

            var html = '';
            html += `
            <style>.interter {
                width: 100%;
                min-height: 140px;
                background: rgba(0, 0, 0, 0.15);
                border: 1px solid rgba(69, 204, 255, 0.15);
                border-radius: 4px;
                cursor: pointer;
                &::after {
                  content: '';
                  display: block;
                  clear: both;
                }
              }

              .left-img {
                float: left;
                padding: 16px;
                height: 104px;
                width: 104px;
              }

              .right-value {
                padding: 16px;
                float:right;
                width: 250px;
                .device-no {
                  font-size: 14px;
                  color: #7b97a2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .device-value11 {
                  font-size: 14px;
                  color: #2ec6ff;
                  font-weight: 400;
                  &.null-value {
                    color: grey;
                  }
                }
                .device-value8 {
                  font-size: 14px;
                  color: #2ec6ff;
                  font-weight: 400;
                  &.null-value {
                    color: grey;
                  }
                }
              }

              .bottom-status {
                width: 100%;
                float: left;
                padding: 0 14px;
                margin-bottom: 14px;
                ul {
                  background: rgba(255, 255, 255, 0.08);
                  height: 20px;
                  border-radius: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  overflow: hidden;
                  .dotted {
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    background: #475766;
                    border-radius: 7px;
                    margin: 0 5px;
                    &.power-status {
                      background: #32f465;
                      box-shadow: 0px 0px 4px 1px rgba(49, 255, 77, 0.69);
                    }
                  }
                }
              }</style>
            <div
                class="interter"
              >
                <div class="left-img">
                  <img
                    src="${
                      '../../../../assets/inverters/' + res.runStatus + '.png'
                    }"
                    alt=""
                  />
                </div>
                <div class="right-value">
                  <div class="device-no">${res.deviceName}</div>
                  <div class="device-no">安装位置：${res.areaName}</div>
                  <div class="device-no">设备型号：${res.modelNo}</div>
                  <div
                    class="device-value11"

                    title="设备状态"
                  >
                   设备状态：${res.runStatusText}
                  </div>
                  <div
                    class="device-value11 ${!res.value11 ? 'null-value' : ''}"

                    title="实时功率 KW"
                  >
                  实时功率：${res.value11 ? res.value11 + ' KW' : '--'}
                  </div>
                  <div
                    class="device-value8 ${!res.value8 ? 'null-value' : ''}"
                    title="今日发电 kWh"
                  >今日发电：${res.value8 ? res.value8 + ' kWh' : '--'}
                  </div>
                </div>
                <div class="bottom-status">
                  <ul>
                  ${res.pvarrayStatus
                    .map((item) => {
                      return `<li
                      class="dotted ${item === 1 ? 'power-status' : ''}"
                    ></li>`;
                    })
                    .join('')}
                  </ul>
                </div>
              </div>`;
            if (this.NbPopup) {
              this.NbPopup.remove();
            }

            this.NbPopup = new CesiumPopup({
              title: data.deviceNo,
            })
              .setPosition(position)
              .setHTML(html)
              .addTo(viewer)
              .setTitle('逆变器信息');

            const that = this;

            this.NbPopup.on('close', function () {
              console.log('close');
              that.NbPopup = null;
            });

            this.NbPopup.on('open', function () {
              console.log('open');
            });
            this.NbPopup.on('click', function () {
              that.deviceDetail(res.deviceId, res.deviceType);
            });
          } else if (!data) {
            // if (this.NbPopup) {
            //   this.NbPopup.remove();
            //   this.NbPopup = null;
            // }
          }
        } else {
          let pickedEntity = Cesium.defined(pick) ? pick.id : undefined;
          if (!pickedEntity && Cesium.defined(pick) && pick._instanceId) {
            pickedEntity = { name: pick._instanceId, id: pick._instanceId };
          }

          if (Cesium.defined(pickedEntity) && Cesium.defined(pickedEntity.id)) {
            if (
              this.earth.sceneTree.$refs[pickedEntity.name] &&
              this.stationTypeMap[pickedEntity.name]
            ) {
              if (this.previousHoverId !== pickedEntity.name) {
                const stationInfo = this.stationTypeMap[pickedEntity.name];
                this.winPos = JSON.parse(
                  JSON.stringify(
                    this.earth.sceneTree.$refs[pickedEntity.name].czmObject
                      .winPos
                  )
                );

                this.previousHoverId = pickedEntity.name;
                const data =
                  await this.earthmapbgservice.getStationDataDashboard({
                    stationId: pickedEntity.name,
                    scene: this.scene,
                  });

                this.stationData = {
                  ...data,
                  title: stationInfo.stationName,
                  region: stationInfo.region,
                  capacity: stationInfo.capacity,
                };

                this.showTip = true;
              }
            }

            if (
              this.earth.sceneTree.$refs[pickedEntity.name] &&
              this.stationAreaMap[pickedEntity.name]
            ) {
              if (this.previousHoverId !== pickedEntity.name) {
                this.stationAreaData = this.stationAreaMap[pickedEntity.name];
                this.winPos = JSON.parse(
                  JSON.stringify(
                    this.earth.sceneTree.$refs[pickedEntity.name].czmObject
                      .winPos
                  )
                );
                this.previousHoverId = pickedEntity.name;
                this.showAreaTip = true;
              }
            }

            if (
              this.earth.sceneTree.$refs[pickedEntity.name] &&
              this.stationMap[pickedEntity.name]
            ) {
              if (this.previousHoverId !== pickedEntity.name) {
                this.winPos = JSON.parse(
                  JSON.stringify(
                    this.earth.sceneTree.$refs[pickedEntity.name].czmObject
                      .winPos
                  )
                );
                let stationInfoData = [];
                const data = this.stationMap[pickedEntity.name].customProps;
                data.map((item) => {
                  if (stationInfoData[item.g]) {
                    stationInfoData[item.g].push(item);
                  } else {
                    stationInfoData[item.g] = [item];
                  }
                });
                stationInfoData = stationInfoData.filter((item) => item);
                this.stationInfoData = {
                  name: pickedEntity.name,
                  infos: stationInfoData,
                  id: this.stationMap[pickedEntity.name].id,
                  type: this.stationMap[pickedEntity.name].type,
                };
                if (this.stationMap[pickedEntity.name].tip === 1) {
                  this.showStationTip = true;
                }
                this.previousHoverId = pickedEntity.name;
              }
            }
          } else {
            this.showTip = false;
            this.showAreaTip = false;
            this.showStationTip = false;
            this.previousHoverId = null;
          }
        }
      }, 200);
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // 单击事件
    // this.handler.setInputAction((movement) => {
    //   if (this.timer) {
    //     clearTimeout(this.timer);
    //     this.timer = null;
    //   }

    //   this.timer = setTimeout(() => {
    //     const pick = this.viewer.scene.pick(movement.position);
    //     console.log(this.earth.sceneTree.$refs, pick);

    //     var path = this.earth.sceneTree.$refs.path.czmObject;
    //     if (path.creating || path.editing) {
    //       return;
    //     }
    //     if (pick instanceof Cesium.Cesium3DTileFeature) {
    //       // const data = this.getParentNode(
    //       //   this.scenesTree,
    //       //   pick.getProperty('id')
    //       // );
    //       // var cartesian = this.viewer.scene.pickPosition(movement.position);
    //       // // console.log('笛卡尔坐标:' + cartesian);
    //       // var ellipsoid = this.viewer.scene.globe.ellipsoid;
    //       // var xyz = new Cesium.Cartesian3(
    //       //   cartesian.x,
    //       //   cartesian.y,
    //       //   cartesian.z
    //       // );
    //       // var wgs84 = ellipsoid.cartesianToCartographic(xyz);
    //       // console.log({
    //       //   lon: Cesium.Math.toDegrees(wgs84.longitude),
    //       //   lat: Cesium.Math.toDegrees(wgs84.latitude),
    //       // });
    //       // window.postMessage(
    //       //   JSON.stringify({ data, event: 'LEFT_CLICK' }),
    //       //   '*'
    //       // );
    //       // // console.log('postMessage', { data, event: 'LEFT_CLICK' });
    //       // this.showInfo(data);
    //     }

    //     // if (
    //     //   Cesium.defined(pick) &&
    //     //   pick.id &&
    //     //   pick.id.id &&
    //     //   this.getClickEvent
    //     // ) {
    //     //   console.log(pick.id.id.split('-'));
    //     // }
    //   }, 200);
    // }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  getParentNode(tree, id) {
    let data = [];
    const getItemById = (tree, id) => {
      for (const item of tree) {
        if (item.id === id) {
          data.push(item);
          return true;
        }
        if (!item.children) {
          continue;
        } else {
          if (getItemById(item.children, id)) {
            data.push(item);
            return true;
          }
        }
      }
    };
    getItemById(tree, id);
    if (data.length > 0) {
      const nb = data[data.length - 3];

      if (nb && nb.name.startsWith('D_NB_')) {
        return nb;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // 添加模型
  public addModel(models = []) {
    // 移除页面所有模型
    models.map((model) => {
      const position = Cesium.Cartesian3.fromDegrees(
        model.position[0],
        model.position[1],
        model.position[2]
      );
      let hpr = new Cesium.HeadingPitchRoll(
        Cesium.Math.toRadians(model.rotation[0]),
        Cesium.Math.toRadians(model.rotation[1]),
        Cesium.Math.toRadians(model.rotation[2])
      );
      let orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );

      if (this.viewer.entities.getById(model.id)) {
        return;
      }

      const entity = this.viewer.entities.add({
        id: model.id,
        name: model.model_name,
        position: position,
        orientation: orientation,
        model: {
          show: !!model.show,
          uri: '/resources/earth/models/' + model.type + '.gltf',
          scale: model.scale || 1,
        },
        // point: {
        //   //点
        //   pixelSize: 0,
        //   HeightReference: 1000,
        // },
        // label: model.labelBackground
        //   ? {
        //     //文字标签
        //     text: model.model_name,
        //     font: "20px sans-serif", // 15pt monospace
        //     scale: 1,
        //     style: Cesium.LabelStyle.FILL,
        //     fillColor: Cesium.Color.WHITE,
        //     pixelOffset: model.pixelOffset
        //       ? new Cesium.Cartesian2(
        //         model.pixelOffset[0],
        //         model.pixelOffset[1]
        //       )
        //       : new Cesium.Cartesian2(10, -60), //偏移量
        //     showBackground: true,
        //     backgroundColor: this.getColor(model.labelBackground, 1),
        //     backgroundPadding: new Cesium.Cartesian2(10, 10),
        //     distanceDisplayCondition: model.distanceDisplayCondition
        //       ? new Cesium.DistanceDisplayCondition(
        //         model.distanceDisplayCondition[0],
        //         model.distanceDisplayCondition[1]
        //       )
        //       : null,
        //   }
        //   : null,
      });
      if (model.color) {
        entity.model.color = this.getColor(model.color, model.opacity);
        // "Highlight", "Replace", "Mix"
        entity.model.colorBlendMode = Cesium.ColorBlendMode['MIX'];
      }
      if (model.type === 'O2-导波罩和叶片') {
        this.rollModel.push({ ...model, entity });
      }
    });
  }

  // 获取Color
  public getColor(color = '#ddd', alpha) {
    return Cesium.Color.fromAlpha(
      Cesium.Color.fromCssColorString(color),
      parseFloat(alpha)
    );
  }

  public enterMonitor() {
    if (!this.viewer) {
      return;
    }

    if (!_.isEmpty(this._gfodlines)) {
      this._gfodlines.destroy();
    }

    this.removeCesiumLines();
    // 清除光伏电站
    this.photovoltaicList.map((item) => {
      let getById = this.viewer.entities.getById(item.id);
      this.viewer.entities.remove(getById);
    });
    // 删除电站连线
    if (!_.isEmpty(this._hmodlines)) {
      this._hmodlines.destroy();
    }
    // 删除电站连线
    if (!_.isEmpty(this._sdodlines)) {
      this._sdodlines.destroy();
    }
    // 清除风电电站
    this.windList.map((item) => {
      let getById = this.viewer.entities.getById(item.id);
      let getByIdFan = this.viewer.entities.getById(item.id + '_fan');
      let getByIdColumn = this.viewer.entities.getById(item.id + '_column');
      this.viewer.entities.remove(getById);
      this.viewer.entities.remove(getByIdFan);
      this.viewer.entities.remove(getByIdColumn);
    });

    // 停止旋转
    this.stopRoll();

    this.viewer.scene.globe.enableLighting = false;

    this.earth.sceneTree.root.children.length = 2;

    if (this.NbPopup) {
      this.NbPopup.remove();
    }

    this.earth.sceneTree.root.children.push({
      ref: this.stationHq.stationId || 'hq',
      czmObject: {
        name: this.stationHq.stationId || 'hq',
        xbsjType: 'Pin',
        position: [
          Cesium.Math.toRadians(+this.stationHq.log),
          Cesium.Math.toRadians(+this.stationHq.lat),
          this.stationHq.alt ? +this.stationHq.alt : 0,
        ],
        near: 100,
        imageUrl: '/resources/earth/img/center.png',
      },
    });

    const longitudes = [];
    const latitudes = [];
    this.isCounty = false;

    this.stations
      .filter((item) => {
        return item.log && item.lat;
      })
      .map((item) => {
        longitudes.push(+item.log);
        latitudes.push(+item.lat);

        this.earth.sceneTree.root.children.push({
          ref: item.stationId,
          czmObject: {
            name: item.stationId,
            xbsjType: 'Pin',
            position: [
              Cesium.Math.toRadians(+item.log),
              Cesium.Math.toRadians(+item.lat),
              item.alt ? +item.alt : 0,
            ],
            near: 100,
            imageUrl:
              item.stationClass === '02'
                ? '/resources/earth/img/fengdian.png'
                : '/resources/earth/img/guangfu.png',
          },
        });
        this.stationTypeMap[item.stationId] = item;
      });

    this.centerLongitude = null;
    this.centerLatitude = null;
    if (longitudes.length > 0) {
      // this.centerLongitude =
      //   longitudes.reduce((a, b) => +a + +b) / longitudes.length;

      let max = _.max(longitudes);
      let min = _.min(longitudes);
      this.centerLongitude = (max + min) / 2;
    }
    if (latitudes.length > 0) {
      // this.centerLatitude =
      //   latitudes.reduce((a, b) => +a + +b) / latitudes.length;
      let max = _.max(latitudes);
      let min = _.min(latitudes);
      this.centerLatitude = (max + min) / 2;
    }

    this.cameraFlying = true;
    this.viewer.camera.flyToBoundingSphere(
      new Cesium.BoundingSphere(
        Cesium.Cartesian3.fromDegrees(
          this.centerLongitude,
          this.centerLatitude,
          0
        ),
        555000
      ),
      {
        complete: () => {
          this.cameraFlying = false;
        },
      }
    );

    // this.viewer.camera.flyTo({
    //   destination: Cesium.Cartesian3.fromDegrees(118.74388, 31.98945, 1445000.0),
    //   duration: 3,
    //   complete: () => {
    //     this.viewer.camera.flyTo({
    //       destination: {
    //         x: -3009932.3912503878,
    //         y: 5688906.618803118,
    //         z: 2937375.281007291,
    //       },
    //       orientation: {
    //         heading: 0.2466267430192719,
    //         pitch: -0.6519708003798357,
    //         roll: 0.0012950311098034462,
    //       },
    //       duration: 2,
    //       complete: () => {
    //         this.cameraFlying = false;
    //       },
    //     });
    //   },
    // });
    this.loadCesiumLines();
  }

  // 进入光伏电站
  public enterPhotovoltaicPage() {
    if (!this.viewer) {
      return;
    }
    let GFlines = [];
    this.showTip = false;
    this.removeCesiumLines();

    this.earthmapbgservice
      .getDevices({ stationId: this.stationId })
      .then((res) => {
        this.photovoltaicList = res.devices.map((item) => {
          return {
            id: item.id,
            name: item.name,
            position: [+item.log, +item.lat, +item.alt],
            rotation: [
              isNaN(+item.lay) ? 90 : -(+item.lay - 90),
              isNaN(+item.ang) ? 0 : +item.ang,
              0,
            ],
            status: item.state,
            file: item.file,
            type: item.type,
            customProps: item.infos,
            tip: item.tip,
          };
        });

        res.lines.map((item) => {
          let HMline = [];
          item.points
            .filter((point) => {
              return point.log && point.lat && point.alt;
            })
            .map((point) => {
              HMline.push([
                Cesium.Math.toRadians(+point.log),
                Cesium.Math.toRadians(+point.lat),
                +point.alt,
              ]);
            });
          GFlines.push(HMline);
        });

        this.showGfLines(GFlines);

        let photovoltaics = {};
        this.photovoltaicList.map((item) => {
          // this.showPhotovoltaic({
          //   id: item.type + '_' + item.id + '_' + item.name,
          //   name: item.name,
          //   type: item.file,
          //   status: item.status,
          //   position: item.position,
          //   rotation: item.rotation
          // });
          this.earth.sceneTree.root.children.push({
            ref: item.name,
            czmObject: {
              name: item.name,
              xbsjType: 'Pin',
              position: [
                Cesium.Math.toRadians(+item.position[0]),
                Cesium.Math.toRadians(+item.position[1]),
                +item.position[2] + 3,
              ],
              scale: 1,
              imageUrl:
                item.type !== 'ZC' ? this.getImageUrl(item.status) : null,
              show: item.type !== 'ZC',
            },
          });
          this.stationMap[item.name] = item;

          if (photovoltaics[item.type]) {
            photovoltaics[item.type].push({
              id: item.type + '_' + item.id + '_' + item.name,
              name: item.name,
              type: item.file,
              status: item.status,
              position: item.position,
              rotation: item.rotation,
            });
          } else {
            photovoltaics[item.type] = [
              {
                id: item.type + '_' + item.id + '_' + item.name,
                name: item.name,
                type: item.file,
                status: item.status,
                position: item.position,
                rotation: item.rotation,
              },
            ];
          }
        });

        for (const key in photovoltaics) {
          if (Object.prototype.hasOwnProperty.call(photovoltaics, key)) {
            const element = photovoltaics[key];
            this.showPhotovoltaic(element);
          }
        }

        const longitudes = [];
        const latitudes = [];

        this.photovoltaicList
          .filter((item) => {
            return item.position[0] && item.position[1];
          })
          .map((item) => {
            longitudes.push(+item.position[0]);
            latitudes.push(+item.position[1]);
          });

        this.centerLongitude = null;
        this.centerLatitude = null;
        if (longitudes.length > 0) {
          this.centerLongitude =
            longitudes.reduce((a, b) => +a + +b) / longitudes.length;
        }
        if (latitudes.length > 0) {
          this.centerLatitude =
            latitudes.reduce((a, b) => +a + +b) / latitudes.length;
        }
        this.cameraFlying = true;
        this.viewer.camera.flyToBoundingSphere(
          new Cesium.BoundingSphere(
            Cesium.Cartesian3.fromDegrees(
              this.centerLongitude,
              this.centerLatitude,
              0
            ),
            200
          ),
          {
            complete: () => {
              this.cameraFlying = false;
            },
          }
        );
      });
  }

  // 进入整县光伏
  public async enterCountyPhotovoltaicPage() {
    this.showTip = false;
    this.removeCesiumLines();

    // 删除电站连线
    if (!_.isEmpty(this._hmodlines)) {
      this._hmodlines.destroy();
    }
    // 删除电站连线
    if (!_.isEmpty(this._sdodlines)) {
      this._sdodlines.destroy();
    }
    // 删除电站连线
    if (!_.isEmpty(this._gfodlines)) {
      this._gfodlines.destroy();
    }

    const data = await this.earthmapbgservice.getStationAreas({
      stationId: this.stationId,
    });

    this.countyDataEvent.emit([
      {
        text: '子站数量',
        value: data.areaCount,
        type: 1,
      },
      {
        text: '异常子站',
        value: data.abnormalAareaCount,
        type: 1,
        params: {
          runStatus: 0,
        },
      },
      {
        text: '逆变器总数',
        value: data.nbCount,
        type: 2,
        params: {},
      },
      {
        text: '离线逆变器',
        value: data.offlineNbCount,
        type: 2,
        params: {
          runStatus: 2,
        },
      },
      {
        text: '告警逆变器',
        value: data.alarmNbCount,
        type: 2,
        params: {
          runStatus: 1,
        },
      },
    ]);
    const longitudes = [];
    const latitudes = [];

    this.subStations = [];
    data.substations
      .filter((item) => {
        return item.area.longitude && item.area.latitude;
      })
      .map((item) => {
        if (item.area.longitude) {
          longitudes.push(item.area.longitude);
        }
        if (item.area.latitude) {
          latitudes.push(item.area.latitude);
        }
        this.subStations.push(item.area.areaId);
        this.earth.sceneTree.root.children.push({
          ref: item.area.areaId,
          czmObject: {
            name: item.area.areaId,
            xbsjType: 'Pin',
            position: [
              Cesium.Math.toRadians(+item.area.longitude),
              Cesium.Math.toRadians(+item.area.latitude),
              0,
            ],
            near: 1,
            imageUrl: this.getImageUrl(item.area.areaStatus),
            customProp: JSON.stringify({
              areaStatus: item.area.areaStatus,
              runStatus: item.area.runStatus,
            }),
          },
        });
        this.stationAreaMap[item.area.areaId] = item;
      });

    this.centerLongitude = null;
    this.centerLatitude = null;
    if (longitudes.length > 0) {
      this.centerLongitude =
        longitudes.reduce((a, b) => +a + +b) / longitudes.length;
    }
    if (latitudes.length > 0) {
      this.centerLatitude =
        latitudes.reduce((a, b) => +a + +b) / latitudes.length;
    }

    // this.earth.sceneTree.root.children.push({
    //   czmObject: {
    //     xbsjType: 'Tileset',
    //     name: '三维瓦片1x',
    //     url: 'resources/model3/tileset.json',
    //     // "url": "https://lab.earthsdk.com/model/8c5299e0ce5f11eab7a4adf1d6568ff7/tileset.json", // 跨平台优化3dtiles数据
    //     xbsjClippingPlanes: {},
    //     xbsjUseOriginTransform: false, //是否启用原先的状态
    //     xbsjPosition: [
    //       Cesium.Math.toRadians(this.centerLongitude),
    //       Cesium.Math.toRadians(this.centerLatitude),
    //       0,
    //     ],
    //     skipLevelOfDetail: false,
    //   },
    //   ref: 'tileset',
    // });

    // //添加图片
    // this.earth.sceneTree.root.children.push({
    //   ref: 'groundImage',
    //   czmObject: {
    //     xbsjType: 'GroundImage',
    //     position: [
    //       Cesium.Math.toRadians(+data.substations[0].area.longitude),
    //       Cesium.Math.toRadians(+data.substations[0].area.latitude),
    //       10,
    //     ],
    //     width: 320,
    //   },
    // });
    // console.log(this.earth.sceneTree.$refs);

    // var groundImage = this.earth.sceneTree.$refs.groundImage.czmObject;
    // groundImage.imageUrls = ['resources/earth/station_default.png'];

    this.cameraFlying = true;
    this.viewer.camera.flyToBoundingSphere(
      new Cesium.BoundingSphere(
        Cesium.Cartesian3.fromDegrees(
          this.centerLongitude,
          this.centerLatitude,
          0
        ),
        15000
      ),
      {
        complete: () => {
          this.cameraFlying = false;
        },
      }
    );
  }
  // 进入3d电站
  public async enter3dStation() {
    this.showTip = false;
    this.removeCesiumLines();

    // 删除电站连线
    if (!_.isEmpty(this._hmodlines)) {
      this._hmodlines.destroy();
    }
    // 删除电站连线
    if (!_.isEmpty(this._sdodlines)) {
      this._sdodlines.destroy();
    }
    // 删除电站连线
    if (!_.isEmpty(this._gfodlines)) {
      this._gfodlines.destroy();
    }

    this.earth.sceneTree.root.children.push({
      czmObject: {
        xbsjType: 'Tileset',
        name: '三维瓦片1x',
        url: `${this.appconfig.fileEndpointUrl}/station3d/${this.stationId}/tileset.json`,
        // url: `resources/${this.stationId}/tileset.json`,
        xbsjClippingPlanes: {},
        xbsjUseOriginTransform: false, //是否启用原先的状态
        xbsjPosition: [
          Cesium.Math.toRadians(this.station3dLocation.lng),
          Cesium.Math.toRadians(this.station3dLocation.lat),
          0,
        ],
        xbsjScale: [0.2, 0.2, 0.2],
        skipLevelOfDetail: false,
      },
      ref: 'tileset',
    });

    fetch(
      // `resources/${this.stationId}/scenetree.json`
      `${this.appconfig.fileEndpointUrl}/station3d/${this.stationId}/scenetree.json`
    )
      .then((response) => response.json())
      .then((json) => {
        this.scenesTree = json.scenes;
      });

    // //添加图片
    // this.earth.sceneTree.root.children.push({
    //   ref: 'groundImage',
    //   czmObject: {
    //     xbsjType: 'GroundImage',
    //     position: [
    //       Cesium.Math.toRadians(this.station3dLocation.lng),
    //       Cesium.Math.toRadians(this.station3dLocation.lat),
    //       110,
    //     ],
    //     width: 1000,
    //   },
    // });
    // console.log(this.earth.sceneTree.$refs);

    // var groundImage = this.earth.sceneTree.$refs.groundImage.czmObject;
    // groundImage.imageUrls = ['resources/earth/station_default.jpg'];

    this.cameraFlying = true;
    this.viewer.camera.flyToBoundingSphere(
      new Cesium.BoundingSphere(
        Cesium.Cartesian3.fromDegrees(
          this.station3dLocation.lng,
          this.station3dLocation.lat,
          0
        ),
        5000
      ),
      {
        complete: () => {
          this.cameraFlying = false;
        },
      }
    );
  }

  public enterWindPowerPage() {
    let SDlines = [];
    let HMlines = [];
    this.showTip = false;
    this.removeCesiumLines();

    for (let index = 0; index < this.windList.length; index++) {
      const element: any = this.windList[index];
      let status = '';
      switch (element.status) {
        case 'online':
          element.labelBackground = '#002A4B';
          break;
        case 'offline':
          element.labelBackground = '#666666';
          break;
        case 'error':
          element.labelBackground = '#4B1400';
          break;
        default:
          break;
      }
      if (element.type === 'station') {
        this.showStation(element);
        SDlines.push([
          Cesium.Math.toRadians(element.longitude),
          Cesium.Math.toRadians(element.latitude),
          element.height,
        ]);
      } else {
        this.showFanModel(element);
        if (element.type === 'SD') {
          SDlines.push([
            Cesium.Math.toRadians(element.longitude),
            Cesium.Math.toRadians(element.latitude),
            element.height,
          ]);
        } else {
          let HMline = [];
          HMline.push([
            Cesium.Math.toRadians(element.longitude),
            Cesium.Math.toRadians(element.latitude),
            element.height,
          ]);
          HMline.push([
            Cesium.Math.toRadians(122.4100596351664),
            Cesium.Math.toRadians(29.83221119383473),
            34.48227676999591,
          ]);
          HMlines.push(HMline);
        }
      }
    }

    this.showSDLines(SDlines);
    this.showHMLines(HMlines);
    this.cameraFlying = true;
    this.viewer.camera.flyTo({
      destination: {
        x: -2971559.4278713204,
        y: 4682310.814261754,
        z: 3148762.2664904916,
      },
      orientation: {
        heading: 0.08644473118549989,
        pitch: -0.423986662520343,
        roll: 6.283105684755056,
      },
      complete: () => {
        this.cameraFlying = false;
      },
    });
    this.startRoll();
  }

  // 通过 locations.json 获取位置
  public getLocaion(locationItem) {
    const [x, y, z] = locationItem.sphere;
    const { longitude, latitude, height } = Cesium.Cartographic.fromCartesian({
      x,
      y,
      z,
    });
    return {
      position: [
        Cesium.Math.toDegrees(longitude),
        Cesium.Math.toDegrees(latitude),
        height,
      ],
      rotation: [0, 0, 0],
    };
  }

  public showSDLines(positions) {
    this._sdodlines = new XE.Obj.ODLines(this.earth);

    this._sdodlines.data = [
      {
        positions: positions, // [经度，纬度，高度] 经纬度单位是弧度，其中高度可以省略
        width: 2.0, // 宽度
        color: [1, 1, 0, 0], // 颜色，rgba分量，都是0-1之间
        bgColor: [0.43, 0.96, 0.71, 1], // 背景颜色，rgba分量，都是0-1之间
        bidirectional: 0, // 运行形式：0 正向运动 1 反向运动 2 双向运动
        startTime: 0.5, // OD线从某个时刻开始播放
        duration: 2.0, // OD线从头到尾播放的总时长
      },
    ];

    this._sdodlines.timeDuration = 3;
    this._sdodlines.playing = true;
  }
  public showHMLines(positions) {
    this._hmodlines = new XE.Obj.ODLines(this.earth);

    this._hmodlines.data = positions.map((item) => ({
      positions: item, // [经度，纬度，高度] 经纬度单位是弧度，其中高度可以省略
      width: 2.0, // 宽度
      color: [1, 1, 0, 0], // 颜色，rgba分量，都是0-1之间
      bgColor: [0.43, 0.96, 0.71, 1], // 背景颜色，rgba分量，都是0-1之间
      bidirectional: 0, // 运行形式：0 正向运动 1 反向运动 2 双向运动
      startTime: 0.5, // OD线从某个时刻开始播放
      duration: 2.0, // OD线从头到尾播放的总时长
    }));

    this._hmodlines.timeDuration = 3;
    this._hmodlines.playing = true;
  }

  public showGfLines(positions) {
    this._gfodlines = new XE.Obj.ODLines(this.earth);

    this._gfodlines.data = positions.map((item) => ({
      positions: item, // [经度，纬度，高度] 经纬度单位是弧度，其中高度可以省略
      width: 2.0, // 宽度
      color: [1, 1, 0, 0], // 颜色，rgba分量，都是0-1之间
      bgColor: [0.43, 0.96, 0.71, 1], // 背景颜色，rgba分量，都是0-1之间
      bidirectional: 0, // 运行形式：0 正向运动 1 反向运动 2 双向运动
      startTime: 0.5, // OD线从某个时刻开始播放
      duration: 2.0, // OD线从头到尾播放的总时长
    }));

    this._gfodlines.timeDuration = 3;
    this._gfodlines.playing = true;
  }

  public showPhotovoltaic(photovoltaic) {
    // this.addModel([
    //   {
    //     id: photovoltaic.id,
    //     model_name: photovoltaic.name,
    //     type: photovoltaic.type,
    //     color: photovoltaic.type === "zc" ? "#23233b" : "#ddd",
    //     opacity: 1,
    //     show: true,
    //     position: photovoltaic.position,
    //     rotation: photovoltaic.rotation,
    //     scale: 0.001,
    //     status: photovoltaic.status,
    //   },
    // ]);

    let instances = [];
    photovoltaic.map((model) => {
      var modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
        Cesium.Cartesian3.fromDegrees(
          model.position[0],
          model.position[1],
          model.position[2]
        ),
        new Cesium.HeadingPitchRoll(
          Cesium.Math.toRadians(model.rotation[0]),
          Cesium.Math.toRadians(model.rotation[1]),
          Cesium.Math.toRadians(model.rotation[2])
        )
      );
      Cesium.Matrix4.multiplyByUniformScale(modelMatrix, 0.001, modelMatrix);

      instances.push({
        modelMatrix: modelMatrix,
        batchId: model.name,
        id: model.name,
      });
    });

    const modelinstancecollection = new Cesium.ModelInstanceCollection({
      url: '/resources/earth/models/' + photovoltaic[0].type + '.gltf',
      instances: instances,
      luminanceAtZenith: 0.2,
      allowPicking: true,
    });

    this.modelinstancecollection[photovoltaic[0].type] =
      modelinstancecollection;

    this.viewer.scene.primitives.add(modelinstancecollection);
  }

  public showStation(station) {
    this.addModel([
      {
        id: station.id,
        model_name: station.name,
        type: 'station',
        color: '#fff',
        opacity: 1,
        show: true,
        position: [station.longitude, station.latitude, station.height || 0],
        rotation: [0, 0, 0],
        scale: 0.001,
        status: station.status,
        labelBackground: station.labelBackground,
      },
    ]);
  }

  public showFanModel(fan) {
    this.addModel([
      {
        id: fan.id,
        model_name: fan.name,
        type: 'O3-机舱罩',
        color: '#fff',
        opacity: 1,
        show: true,
        position: [fan.longitude, fan.latitude, (fan.height || 0) + 230],
        rotation: [0, 0, 0],
        scale: 0.05,
        status: fan.status,
        labelBackground: fan.labelBackground,
        distanceDisplayCondition: [100, 12000],
      },
      {
        id: fan.id + '_fan',
        model_name: fan.name,
        type: 'O2-导波罩和叶片',
        color: '#fff',
        opacity: 1,
        show: true,
        position: [fan.longitude, fan.latitude, (fan.height || 0) + 230],
        rotation: [0, 0, 0],
        scale: 0.05,
        status: fan.status,
      },
      {
        id: fan.id + '_column',
        model_name: fan.name,
        type: 'O1-立柱',
        color: '#fff',
        opacity: 1,
        show: true,
        position: [fan.longitude, fan.latitude, (fan.height || 0) + 230],
        rotation: [0, 0, 0],
        scale: 0.05,
        status: fan.status,
      },
    ]);
  }

  public startRoll() {
    if (this.rollInterval.length > 0) {
      return;
    }
    this.rollSpeed = 5;
    const object = this.rollModel;
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        const model = object[key];
        if (model.status !== 'offline') {
          const interval = setInterval(() => {
            this.roll += this.rollSpeed;
            if (this.roll === 360) {
              this.roll = 0;
            }
            let hpr = new Cesium.HeadingPitchRoll(
              Cesium.Math.toRadians(model.rotation[0]),
              Cesium.Math.toRadians(model.rotation[1]),
              Cesium.Math.toRadians(this.roll)
            );
            let orientation = Cesium.Transforms.headingPitchRollQuaternion(
              model.entity.position.getValue(),
              hpr
            );
            model.entity.orientation = orientation;
          }, 160);
          this.rollInterval.push(interval);
        }
      }
    }
  }

  public stopRoll() {
    if (this.rollInterval.length > 0) {
      let speedInterval = setInterval(() => {
        if (this.rollSpeed === 0) {
          this.rollInterval.map((item) => {
            clearInterval(item);
            item = null;
          });
          this.rollInterval = [];
          clearInterval(speedInterval);
          speedInterval = null;
        }
        this.rollSpeed--;
      }, 500);
    }
  }

  /**
   * deviceDetail
   */
  public deviceDetail(deviceId, deviceType) {
    if (deviceType === 'ZC') {
      return;
    }
    this.isShow = true;
    this.showConfig = true;
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId: this.stationId,
      deviceType,
      showConfig: true,
    });
  }

  back() {
    this.showConfig = false;
  }

  goDeviceList(areaId) {
    this.stationmonitorservice.setAreaIdsCache(areaId);
    this.router.navigate(['/yxjs/monitorStation/deviceList']);
  }

  getImageUrl(type) {
    switch (type) {
      case '01':
        return '/resources/earth/icon/22/gray.png';
      case '02':
        return '/resources/earth/icon/22/yellow.png';
      case '03':
        return '/resources/earth/icon/22/green.png';
      case '04':
        return '/resources/earth/icon/22/blue.png';
      case '05':
        return '/resources/earth/icon/22/red.png';
      case '0':
        return '/resources/earth/icon/22/black.png';
      case '1':
        return '/resources/earth/icon/22/red.png';
      case '2':
        return '/resources/earth/icon/22/gray.png';
      case '3':
        return '/resources/earth/icon/22/blue.png';
      case 'runing':
        return '/resources/earth/icon/22/blue.png';
      case 'offline':
        return '/resources/earth/icon/22/black.png';
      case 'alarm':
        return '/resources/earth/icon/22/red.png';
      case 'down':
        return '/resources/earth/icon/22/gray.png';
      default:
        return '/resources/earth/icon/22/black.png';
    }
  }

  toggleStatus(type) {
    if (type === this.statusMode) {
      return;
    }
    if (this.statusMode === 'areaStatus') {
      this.subStations.map((item) => {
        const customProp = JSON.parse(
          this.earth.sceneTree.$refs[item].czmObject.customProp
        );
        this.earth.sceneTree.$refs[item].czmObject.imageUrl = this.getImageUrl(
          customProp['runStatus']
        );
      });
      this.statusMode = 'runStatus';
    } else {
      this.subStations.map((item) => {
        const customProp = JSON.parse(
          this.earth.sceneTree.$refs[item].czmObject.customProp
        );
        this.earth.sceneTree.$refs[item].czmObject.imageUrl = this.getImageUrl(
          customProp['areaStatus']
        );
      });
      this.statusMode = 'areaStatus';
    }
  }

  toggleMap(type) {
    if (type === this.mapType) {
      return;
    }
    if (this.mapType === '矢量') {
      this.earth.sceneTree.root.children[0].enabled = true;
      this.earth.sceneTree.root.children[1].enabled = false;
      this.mapType = '卫星';
    } else {
      this.earth.sceneTree.root.children[0].enabled = false;
      this.earth.sceneTree.root.children[1].enabled = true;
      this.mapType = '矢量';
    }
  }

  getDistance(page, isRadius) {
    if (isRadius) {
      switch (page) {
        case 'monitor':
          return 555000;
        case 'county_photovoltaic_page':
          return 15000;
        case 'photovoltaic_page':
          return 200;
        default:
          break;
      }
    } else {
      switch (page) {
        case 'monitor':
          return 555000;
        case 'county_photovoltaic_page':
          return 15000;
        case 'photovoltaic_page':
          return 0;
        default:
          break;
      }
    }
  }

  toggle3D(type) {
    if (type === this.sceneMode) {
      return;
    }
    if (this.sceneMode === '3D') {
      this.viewer.scene.morphTo2D(0);
      this.cameraFlying = true;
      this.viewer.camera.flyToBoundingSphere(
        new Cesium.BoundingSphere(
          Cesium.Cartesian3.fromDegrees(
            this.centerLongitude,
            this.centerLatitude,
            this.getDistance(this.earthStepPage, false)
          ),
          this.getDistance(this.earthStepPage, true)
        ),
        {
          duration: 0,
          complete: () => {
            this.cameraFlying = false;
          },
        }
      );
      this.sceneMode = '2D';
    } else {
      this.viewer.scene.morphTo3D(0);
      this.cameraFlying = true;
      this.viewer.camera.flyToBoundingSphere(
        new Cesium.BoundingSphere(
          Cesium.Cartesian3.fromDegrees(
            this.centerLongitude,
            this.centerLatitude,
            0
          ),
          this.getDistance(this.earthStepPage, true)
        ),
        {
          duration: 0,
          complete: () => {
            this.cameraFlying = false;
          },
        }
      );
      this.sceneMode = '3D';
    }
  }
}
